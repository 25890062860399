<button
  nz-button
  nz-popover
  [nzSize]="nzSize"
  [nzType]="fullSize ? 'default' : 'primary'"
  [nzBlock]="fullSize"
  [(nzPopoverVisible)]="visible"
  (click)="userLotMarkCreate()"
  nzPopoverTrigger="click"
  [nzLoading]="refShared$?.loading | async"
  [nzPopoverContent]="shareLotLink ? copyLink : ('SHARED.SHARE_LOT' | translate)"
>
  <i nz-icon nzType="link" nzTheme="outline"></i>
  <span *ngIf="fullSize">
    {{ 'SHARED.SHARE_LOT' | translate }}
  </span>
</button>

<ng-template #copyLink>
  <nz-tag class="editable-tag" (click)="copyLinkClipboard()" style="cursor: pointer">
    {{ shareLotLink }}
    <i nz-icon nzType="copy" nzTheme="outline" nzSize="small"></i>
  </nz-tag>
</ng-template>

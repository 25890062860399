<div class="swiper-lot">
  <swiper
    #swiper
    class="swiper-lot"
    [config]="configSwiper"
    (activeIndexChange)="setActiveSlide($event.activeIndex)"
  >
    <ng-template swiperSlide *ngFor="let image of lot.images; let i = index">
      <div class="img-wrapper">
        <img [src]="image" (click)="openLightbox(i)" />
      </div>
    </ng-template>
  </swiper>
  <ng-container *ngIf="isUsingSavedImages">
    <div class="swiper-lot__buttons" *akbCheck="['world.akebono.auto.auctioneer']">
      <nz-spin [nzSpinning]="isLoadingPhotos | async">
        <nz-button-group>
          <button
            nz-button
            nz-tooltip
            nzType="primary"
            (click)="movePhoto(activeSlide - 2)"
            [nzTooltipTitle]="'LOT_CAROUSEL.MOVE_LEFT' | translate"
          >
            <i nz-icon nzType="left" nzTheme="outline"></i>
          </button>
          <button
            nz-button
            nz-tooltip
            nzType="primary"
            (click)="movePhoto(activeSlide)"
            [nzTooltipTitle]="'LOT_CAROUSEL.MOVE_RIGHT' | translate"
          >
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </button>
          <button
            nz-button
            nz-tooltip
            nzType="primary"
            [disabled]="!isMovingPhotos"
            (click)="updateLotPhotos(lot.images)"
            [nzTooltipTitle]="'LOT_CAROUSEL.SAVE_CHANGES' | translate"
          >
            <i nz-icon nzType="check" nzTheme="outline"></i>
          </button>
          <button
            nz-button
            nzDanger
            nz-tooltip
            nz-popconfirm
            nzType="primary"
            nzPopconfirmPlacement="bottom"
            (nzOnConfirm)="deletePhoto()"
            [nzTooltipTitle]="'LOT_CAROUSEL.DELETE_PHOTO_TOOLTIP' | translate"
            [nzPopconfirmTitle]="'LOT_CAROUSEL.DELETE_PHOTO' | translate"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </nz-button-group>
      </nz-spin>
    </div>
    <div class="auction-list-tip-button-wrapper">
      <button
        nz-button
        nz-tooltip
        nzType="primary"
        (click)="openAuctionListTipModal()"
        [nzTooltipTitle]="'LOT_CAROUSEL.AUCTION_LIST_TIP_MODAL.BUTTON.TOOLTIP_TITLE' | translate"
      >
        <i nz-icon nzType="question" nzTheme="outline"></i>
      </button>
    </div>
  </ng-container>
</div>

<div nz-row [nzGutter]="[5, 5]" class="thumbs">
  <div
    nz-col
    class="thumb"
    *ngFor="let image of lot.images; let i = index"
    [ngClass]="{ active: i + 1 === activeSlide }"
    [nzXXl]="3"
    [nzXl]="4"
    [nzLg]="6"
    [nzMd]="3"
    [nzSm]="4"
    [nzXs]="6"
  >
    <div class="img-wrapper">
      <img [src]="image" (click)="onClickThumb(i + 1)" />
    </div>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { LotIconComponent } from './components/lot-icon/lot-icon.component';
import { LotIconsComponent } from './lot-icons.component';

@NgModule({
  declarations: [LotIconsComponent, LotIconComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NzToolTipModule,
    NzSpinModule,
    NzGridModule,
  ],
  exports: [LotIconsComponent],
})
export class LotIconsModule {}

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid DateTime, transported as a iso8601 string */
  DateTime: any;
  /** An lot ID */
  LotID: any;
};

export type Query = {
  __typename?: 'Query';
  aleadoAutoAuction?: Maybe<AleadoAutoAuction>;
  aleadoAutoAuctions?: Maybe<AleadoAutoAuctionPaginatedList>;
  aleadoAutoCompanies?: Maybe<AleadoAutoCompanyPaginatedList>;
  aleadoAutoCompany?: Maybe<AleadoAutoCompany>;
  aleadoAutoModels?: Maybe<AleadoAutoModelPaginatedList>;
  autoAuction?: Maybe<AutoAuction>;
  autoAuctions?: Maybe<AutoAuctionPaginatedList>;
  autoColor?: Maybe<AutoColor>;
  autoColors?: Maybe<AutoColorPaginatedList>;
  autoCompanies?: Maybe<AutoCompanyPaginatedList>;
  autoCompaniesWithModels?: Maybe<AutoCompanyPaginatedList>;
  autoCompany?: Maybe<AutoCompany>;
  autoLot?: Maybe<AutoLot>;
  autoLots?: Maybe<AutoLotPaginatedList>;
  autoLotsAggregates?: Maybe<AutoLotPaginatedList>;
  autoModels?: Maybe<AutoModelPaginatedList>;
  autoResult?: Maybe<AutoResult>;
  autoResults?: Maybe<AutoResultPaginatedList>;
  autoTruck?: Maybe<AutoTruck>;
  autoTrucks?: Maybe<AutoTruckPaginatedList>;
  dumpVersion: Scalars['String'];
  motoAuction?: Maybe<MotoAuction>;
  motoAuctions?: Maybe<MotoAuctionPaginatedList>;
  motoCompanies?: Maybe<MotoCompanyPaginatedList>;
  motoCompany?: Maybe<MotoCompany>;
  motoLot?: Maybe<MotoLot>;
  motoLots?: Maybe<MotoLotPaginatedList>;
  motoModel?: Maybe<MotoModel>;
  motoModels?: Maybe<MotoModelPaginatedList>;
  onepriceLot?: Maybe<OnepriceLot>;
  onepriceLots?: Maybe<OnepriceLotPaginatedList>;
  onepriceLotsAggregates?: Maybe<OnepriceLotPaginatedList>;
};


export type QueryAleadoAutoAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryAleadoAutoAuctionsArgs = {
  filter?: Maybe<CarsDefaultAleadoAutoAuctionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAleadoAutoAuctionSort>;
};


export type QueryAleadoAutoCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAleadoAutoCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryAleadoAutoModelsArgs = {
  filter?: Maybe<CarsDefaultAutoModelFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAutoAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryAutoAuctionsArgs = {
  filter?: Maybe<CarsDefaultAutoAuctionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoAuctionSort>;
};


export type QueryAutoColorArgs = {
  id: Scalars['ID'];
};


export type QueryAutoColorsArgs = {
  filter?: Maybe<CarsDefaultAutoColorFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoColorSort>;
};


export type QueryAutoCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAutoCompaniesWithModelsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAutoCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryAutoLotArgs = {
  id: Scalars['LotID'];
};


export type QueryAutoLotsArgs = {
  filter?: Maybe<CarsDefaultAutoLotFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoLotSort>;
};


export type QueryAutoLotsAggregatesArgs = {
  filter?: Maybe<CarsDefaultAutoLotFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoLotSort>;
};


export type QueryAutoModelsArgs = {
  filter?: Maybe<CarsDefaultAutoModelFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryAutoResultArgs = {
  id: Scalars['ID'];
};


export type QueryAutoResultsArgs = {
  filter?: Maybe<CarsDefaultAutoResultFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoResultSort>;
};


export type QueryAutoTruckArgs = {
  id: Scalars['ID'];
};


export type QueryAutoTrucksArgs = {
  filter?: Maybe<CarsDefaultAutoTruckFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultAutoTruckSort>;
};


export type QueryMotoAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryMotoAuctionsArgs = {
  filter?: Maybe<CarsDefaultMotoAuctionFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultMotoAuctionSort>;
};


export type QueryMotoCompaniesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryMotoCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryMotoLotArgs = {
  id: Scalars['LotID'];
};


export type QueryMotoLotsArgs = {
  filter?: Maybe<CarsDefaultMotoLotFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultMotoLotSort>;
};


export type QueryMotoModelArgs = {
  id: Scalars['ID'];
};


export type QueryMotoModelsArgs = {
  filter?: Maybe<CarsDefaultMotoModelFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryOnepriceLotArgs = {
  id: Scalars['LotID'];
};


export type QueryOnepriceLotsArgs = {
  filter?: Maybe<CarsDefaultOnepriceLotFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultOnepriceLotSort>;
};


export type QueryOnepriceLotsAggregatesArgs = {
  filter?: Maybe<CarsDefaultOnepriceLotFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<CarsDefaultOnepriceLotSort>;
};

export type AleadoAutoAuction = {
  __typename?: 'AleadoAutoAuction';
  auctionSystem?: Maybe<Scalars['String']>;
  autoTranslation: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isOneprice?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numLots?: Maybe<Scalars['Int']>;
};

export type CarsDefaultAleadoAutoAuctionFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CreatedAtFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
};

export type CarsDefaultAleadoAutoAuctionSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CreatedAtSort = {
  direction?: Maybe<Scalars['String']>;
};

export type UpdatedAtSort = {
  direction?: Maybe<Scalars['String']>;
};

export type AleadoAutoAuctionPaginatedList = {
  __typename?: 'AleadoAutoAuctionPaginatedList';
  distinct?: Maybe<Array<AleadoAutoAuction>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AleadoAutoAuction>;
  total: Scalars['Int'];
};


export type AleadoAutoAuctionPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoAuctionPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoAuctionPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoAuctionPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type ValueWithCount = {
  __typename?: 'ValueWithCount';
  count?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type AleadoAutoCompanyPaginatedList = {
  __typename?: 'AleadoAutoCompanyPaginatedList';
  distinct?: Maybe<Array<AleadoAutoCompany>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AleadoAutoCompany>;
  total: Scalars['Int'];
};


export type AleadoAutoCompanyPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoCompanyPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoCompanyPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoCompanyPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AleadoAutoCompany = {
  __typename?: 'AleadoAutoCompany';
  autoModel?: Maybe<AleadoAutoModel>;
  autoModels: Array<AleadoAutoModel>;
  companyId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type AleadoAutoCompanyAutoModelArgs = {
  id: Scalars['ID'];
};

export type AleadoAutoModel = {
  __typename?: 'AleadoAutoModel';
  autoLotsTotal: Scalars['Int'];
  companyRef: Scalars['Int'];
  id: Scalars['ID'];
  modelId: Scalars['ID'];
  name: Scalars['String'];
};

export type CarsDefaultAutoModelFilter = {
  companyRef?: Maybe<CompanyRefFilter>;
};

export type CompanyRefFilter = {
  eq?: Maybe<Scalars['ID']>;
};

export type AleadoAutoModelPaginatedList = {
  __typename?: 'AleadoAutoModelPaginatedList';
  distinct?: Maybe<Array<AleadoAutoModel>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AleadoAutoModel>;
  total: Scalars['Int'];
};


export type AleadoAutoModelPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoModelPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoModelPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AleadoAutoModelPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoAuction = {
  __typename?: 'AutoAuction';
  auctionSystem?: Maybe<Scalars['String']>;
  autoTranslation: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isOneprice?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  numLots?: Maybe<Scalars['Int']>;
};

export type CarsDefaultAutoAuctionFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CarsDefaultAutoAuctionSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AutoAuctionPaginatedList = {
  __typename?: 'AutoAuctionPaginatedList';
  distinct?: Maybe<Array<AutoAuction>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoAuction>;
  total: Scalars['Int'];
};


export type AutoAuctionPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoAuctionPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoAuctionPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoAuctionPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoColor = {
  __typename?: 'AutoColor';
  colorId: Scalars['ID'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
};

export type CarsDefaultAutoColorFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CarsDefaultAutoColorSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AutoColorPaginatedList = {
  __typename?: 'AutoColorPaginatedList';
  distinct?: Maybe<Array<AutoColor>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoColor>;
  total: Scalars['Int'];
};


export type AutoColorPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoColorPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoColorPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoColorPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoCompanyPaginatedList = {
  __typename?: 'AutoCompanyPaginatedList';
  distinct?: Maybe<Array<AutoCompany>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoCompany>;
  total: Scalars['Int'];
};


export type AutoCompanyPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoCompanyPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoCompanyPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoCompanyPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoCompany = {
  __typename?: 'AutoCompany';
  autoModel?: Maybe<AutoModel>;
  autoModels: Array<AutoModel>;
  companyId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type AutoCompanyAutoModelArgs = {
  id: Scalars['ID'];
};

export type AutoModel = {
  __typename?: 'AutoModel';
  autoLotsTotal: Scalars['Int'];
  companyRef: Scalars['Int'];
  id: Scalars['ID'];
  modelId: Scalars['ID'];
  name: Scalars['String'];
};

export type AutoLot = {
  __typename?: 'AutoLot';
  auctionSystem: Scalars['String'];
  auctRef?: Maybe<Scalars['ID']>;
  autoAuction?: Maybe<AutoAuction>;
  autoCompany: AutoCompany;
  autoModel: AutoModel;
  autoResult?: Maybe<AutoResult>;
  autoTruck?: Maybe<AutoTruck>;
  awdNum?: Maybe<Scalars['Int']>;
  bid: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  colorEn?: Maybe<Scalars['String']>;
  colorRef?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['String']>;
  companyEn?: Maybe<Scalars['String']>;
  companyRef?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  datetime: Scalars['DateTime'];
  displacement?: Maybe<Scalars['String']>;
  displacementNum?: Maybe<Scalars['Int']>;
  downloadTime?: Maybe<Scalars['DateTime']>;
  endPrice?: Maybe<Scalars['String']>;
  endPriceNum?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Scalars['String']>;
  equipmentEn?: Maybe<Scalars['String']>;
  id: Scalars['LotID'];
  inspection?: Maybe<Scalars['String']>;
  inspectionEn?: Maybe<Scalars['String']>;
  isSpecial?: Maybe<Scalars['Boolean']>;
  leftDrive?: Maybe<Scalars['Boolean']>;
  mileage?: Maybe<Scalars['String']>;
  mileageEn?: Maybe<Scalars['String']>;
  mileageNum: Scalars['Int'];
  modelDetailsEn?: Maybe<Scalars['String']>;
  modelGrade?: Maybe<Scalars['String']>;
  modelGradeEn?: Maybe<Scalars['String']>;
  modelName: Scalars['String'];
  modelNameEn?: Maybe<Scalars['String']>;
  modelNameJp: Scalars['String'];
  modelNameRef: Scalars['Int'];
  modelType?: Maybe<Scalars['String']>;
  modelTypeEn?: Maybe<Scalars['String']>;
  modelYear: Scalars['String'];
  modelYearEn?: Maybe<Scalars['String']>;
  parsedData?: Maybe<Scalars['String']>;
  parsedDataEn?: Maybe<Scalars['String']>;
  parsedDataRu?: Maybe<Scalars['String']>;
  picsDownloaded?: Maybe<Scalars['Int']>;
  pictures: Scalars['String'];
  result?: Maybe<Scalars['String']>;
  resultRef?: Maybe<Scalars['Int']>;
  scores?: Maybe<Scalars['String']>;
  scoresEn?: Maybe<Scalars['String']>;
  source?: Maybe<LotSource>;
  startPrice?: Maybe<Scalars['String']>;
  startPriceNum?: Maybe<Scalars['Int']>;
  swType?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  transmissionEn?: Maybe<Scalars['String']>;
  truckRef?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
};

export type AutoResult = {
  __typename?: 'AutoResult';
  id: Scalars['ID'];
  name: Scalars['String'];
  resultId: Scalars['ID'];
};

export type AutoTruck = {
  __typename?: 'AutoTruck';
  id: Scalars['ID'];
  name: Scalars['String'];
  truckId: Scalars['ID'];
};

export enum LotSource {
  Aleado = 'aleado',
  Mikhail = 'mikhail'
}

export type CarsDefaultAutoLotFilter = {
  autoAuctionDayOfWeek?: Maybe<AutoAuctionDayOfWeekFilter>;
  bid?: Maybe<BidFilter>;
  companyRef?: Maybe<CompanyRefFilter>;
  date?: Maybe<DateFilter>;
  datetime?: Maybe<DatetimeFilter>;
  engineVolume?: Maybe<EngineVolumeFilter>;
  id?: Maybe<IdFilter>;
  inclusion?: Maybe<InclusionFilter>;
  leftDrive?: Maybe<LeftDriveFilter>;
  mileage?: Maybe<MileageFilter>;
  modelGradeEn?: Maybe<ModelGradeEnFilter>;
  modelNameRef?: Maybe<ModelNameRefFilter>;
  productionDate?: Maybe<ProductionDateFilter>;
  truckRef?: Maybe<TruckRefFilter>;
  year?: Maybe<YearFilter>;
};

export type AutoAuctionDayOfWeekFilter = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type BidFilter = {
  eq?: Maybe<Scalars['ID']>;
};

export type DateFilter = {
  eq?: Maybe<Array<Scalars['DateTime']>>;
};

export type DatetimeFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
};

export type EngineVolumeFilter = {
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type IdFilter = {
  eq?: Maybe<Array<Scalars['ID']>>;
};

export type InclusionFilter = {
  columns: Array<InclusionFilterInput>;
};

export type InclusionFilterInput = {
  eq: Array<Scalars['String']>;
  fieldName: Scalars['String'];
};

export type LeftDriveFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type MileageFilter = {
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type ModelGradeEnFilter = {
  like?: Maybe<Scalars['String']>;
};

export type ModelNameRefFilter = {
  eq?: Maybe<Scalars['ID']>;
};

export type ProductionDateFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
};

export type TruckRefFilter = {
  eq?: Maybe<Array<Scalars['ID']>>;
};

export type YearFilter = {
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type CarsDefaultAutoLotSort = {
  auctRef?: Maybe<AuctRefSort>;
  bid?: Maybe<BidSort>;
  colorEn?: Maybe<ColorEnSort>;
  date?: Maybe<DateSort>;
  displacementNum?: Maybe<DisplacementNumSort>;
  endPriceNum?: Maybe<EndPriceNumSort>;
  equipmentEn?: Maybe<EquipmentEnSort>;
  mileageNum?: Maybe<MileageNumSort>;
  modelTypeEn?: Maybe<ModelTypeEnSort>;
  modelYearEn?: Maybe<ModelYearEnSort>;
  scoresEn?: Maybe<ScoresEnSort>;
  startPriceNum?: Maybe<StartPriceNumSort>;
};

export type AuctRefSort = {
  direction?: Maybe<Scalars['String']>;
};

export type BidSort = {
  direction?: Maybe<Scalars['String']>;
};

export type ColorEnSort = {
  direction?: Maybe<Scalars['String']>;
};

export type DateSort = {
  direction?: Maybe<Scalars['String']>;
};

export type DisplacementNumSort = {
  direction?: Maybe<Scalars['String']>;
};

export type EndPriceNumSort = {
  direction?: Maybe<Scalars['String']>;
};

export type EquipmentEnSort = {
  direction?: Maybe<Scalars['String']>;
};

export type MileageNumSort = {
  direction?: Maybe<Scalars['String']>;
};

export type ModelTypeEnSort = {
  direction?: Maybe<Scalars['String']>;
};

export type ModelYearEnSort = {
  direction?: Maybe<Scalars['String']>;
};

export type ScoresEnSort = {
  direction?: Maybe<Scalars['String']>;
};

export type StartPriceNumSort = {
  direction?: Maybe<Scalars['String']>;
};

export type AutoLotPaginatedList = {
  __typename?: 'AutoLotPaginatedList';
  distinct?: Maybe<Array<AutoLot>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoLot>;
  total: Scalars['Int'];
};


export type AutoLotPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoLotPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoLotPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoLotPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoModelPaginatedList = {
  __typename?: 'AutoModelPaginatedList';
  distinct?: Maybe<Array<AutoModel>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoModel>;
  total: Scalars['Int'];
};


export type AutoModelPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoModelPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoModelPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoModelPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type CarsDefaultAutoResultFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CarsDefaultAutoResultSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AutoResultPaginatedList = {
  __typename?: 'AutoResultPaginatedList';
  distinct?: Maybe<Array<AutoResult>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoResult>;
  total: Scalars['Int'];
};


export type AutoResultPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoResultPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoResultPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoResultPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type CarsDefaultAutoTruckFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CarsDefaultAutoTruckSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AutoTruckPaginatedList = {
  __typename?: 'AutoTruckPaginatedList';
  distinct?: Maybe<Array<AutoTruck>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<AutoTruck>;
  total: Scalars['Int'];
};


export type AutoTruckPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type AutoTruckPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type AutoTruckPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type AutoTruckPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type MotoAuction = {
  __typename?: 'MotoAuction';
  auctId: Scalars['ID'];
  auctionSystemRef?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  isOneprice?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type CarsDefaultMotoAuctionFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CarsDefaultMotoAuctionSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type MotoAuctionPaginatedList = {
  __typename?: 'MotoAuctionPaginatedList';
  distinct?: Maybe<Array<MotoAuction>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<MotoAuction>;
  total: Scalars['Int'];
};


export type MotoAuctionPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type MotoAuctionPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type MotoAuctionPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type MotoAuctionPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type MotoCompanyPaginatedList = {
  __typename?: 'MotoCompanyPaginatedList';
  distinct?: Maybe<Array<MotoCompany>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<MotoCompany>;
  total: Scalars['Int'];
};


export type MotoCompanyPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type MotoCompanyPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type MotoCompanyPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type MotoCompanyPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type MotoCompany = {
  __typename?: 'MotoCompany';
  companyId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  motoModel?: Maybe<MotoModel>;
  motoModels: Array<MotoModel>;
  name: Scalars['String'];
};


export type MotoCompanyMotoModelArgs = {
  id: Scalars['ID'];
};

export type MotoModel = {
  __typename?: 'MotoModel';
  companyRef: Scalars['Int'];
  modelId: Scalars['Int'];
  motoLotsTotal: Scalars['Int'];
  name: Scalars['String'];
};

export type MotoLot = {
  __typename?: 'MotoLot';
  auctionSystem: Scalars['String'];
  auctRef?: Maybe<Scalars['ID']>;
  bid: Scalars['String'];
  colorEn?: Maybe<Scalars['String']>;
  colorRef?: Maybe<Scalars['Int']>;
  companyEn?: Maybe<Scalars['String']>;
  companyRef?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  datetime: Scalars['DateTime'];
  displacementNum?: Maybe<Scalars['Int']>;
  downloadTime?: Maybe<Scalars['DateTime']>;
  electricityScoreEn?: Maybe<Scalars['String']>;
  endPriceNum?: Maybe<Scalars['Int']>;
  engineScoreEn?: Maybe<Scalars['String']>;
  equipmentEn?: Maybe<Scalars['String']>;
  exteriorScoreEn?: Maybe<Scalars['String']>;
  frameScoreEn?: Maybe<Scalars['String']>;
  frontScoreEn?: Maybe<Scalars['String']>;
  id: Scalars['LotID'];
  inspectionEn?: Maybe<Scalars['String']>;
  isTranslated?: Maybe<Scalars['Boolean']>;
  mileageEn?: Maybe<Scalars['String']>;
  mileageNum?: Maybe<Scalars['Int']>;
  modelDetailsEn?: Maybe<Scalars['String']>;
  modelGradeEn?: Maybe<Scalars['String']>;
  modelNameEn?: Maybe<Scalars['String']>;
  modelNameRef?: Maybe<Scalars['Int']>;
  modelTypeEn?: Maybe<Scalars['String']>;
  modelYearEn?: Maybe<Scalars['String']>;
  motoAuction: MotoAuction;
  motoCompany: MotoCompany;
  motoModel: MotoModel;
  parsedData?: Maybe<Scalars['String']>;
  parsedDataEn?: Maybe<Scalars['String']>;
  parsedDataRu?: Maybe<Scalars['String']>;
  picsAmount: Scalars['Int'];
  picsDownloaded?: Maybe<Scalars['Int']>;
  pictures?: Maybe<Scalars['String']>;
  rearScoreEn?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  resultRef?: Maybe<Scalars['Int']>;
  scoresEn?: Maybe<Scalars['String']>;
  startPriceNum?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
};

export type CarsDefaultMotoLotFilter = {
  bid?: Maybe<BidFilter>;
  companyRef?: Maybe<CompanyRefFilter>;
  date?: Maybe<DateFilter>;
  datetime?: Maybe<DatetimeFilter>;
  engineVolume?: Maybe<EngineVolumeFilter>;
  id?: Maybe<IdFilter>;
  inclusion?: Maybe<InclusionFilter>;
  mileage?: Maybe<MileageFilter>;
  modelGradeEn?: Maybe<ModelGradeEnFilter>;
  modelNameRef?: Maybe<ModelNameRefFilter>;
  motoAuctionDayOfWeek?: Maybe<MotoAuctionDayOfWeekFilter>;
  year?: Maybe<YearFilter>;
};

export type MotoAuctionDayOfWeekFilter = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type CarsDefaultMotoLotSort = {
  auctRef?: Maybe<AuctRefSort>;
  bid?: Maybe<BidSort>;
  date?: Maybe<DateSort>;
  displacementNum?: Maybe<DisplacementNumSort>;
  endPriceNum?: Maybe<EndPriceNumSort>;
  equipmentEn?: Maybe<EquipmentEnSort>;
  mileageNum?: Maybe<MileageNumSort>;
  modelTypeEn?: Maybe<ModelTypeEnSort>;
  modelYearEn?: Maybe<ModelYearEnSort>;
  scoresEn?: Maybe<ScoresEnSort>;
  startPriceNum?: Maybe<StartPriceNumSort>;
};

export type MotoLotPaginatedList = {
  __typename?: 'MotoLotPaginatedList';
  distinct?: Maybe<Array<MotoLot>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<MotoLot>;
  total: Scalars['Int'];
};


export type MotoLotPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type MotoLotPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type MotoLotPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type MotoLotPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type CarsDefaultMotoModelFilter = {
  companyRef?: Maybe<CompanyRefFilter>;
};

export type MotoModelPaginatedList = {
  __typename?: 'MotoModelPaginatedList';
  distinct?: Maybe<Array<MotoModel>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<MotoModel>;
  total: Scalars['Int'];
};


export type MotoModelPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type MotoModelPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type MotoModelPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type MotoModelPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type OnepriceLot = {
  __typename?: 'OnepriceLot';
  auctionSystem: Scalars['String'];
  auctRef?: Maybe<Scalars['ID']>;
  autoAuction: AleadoAutoAuction;
  autoCompany: AleadoAutoCompany;
  autoModel: AleadoAutoModel;
  autoResult: AutoResult;
  autoTruck?: Maybe<AutoTruck>;
  awdNum?: Maybe<Scalars['Int']>;
  bid: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  colorEn?: Maybe<Scalars['String']>;
  colorRef?: Maybe<Scalars['Int']>;
  company?: Maybe<Scalars['String']>;
  companyEn?: Maybe<Scalars['String']>;
  companyRef?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  datetime: Scalars['DateTime'];
  displacement?: Maybe<Scalars['String']>;
  displacementNum?: Maybe<Scalars['Int']>;
  downloadTime: Scalars['DateTime'];
  endPrice?: Maybe<Scalars['String']>;
  endPriceNum?: Maybe<Scalars['Int']>;
  equipment?: Maybe<Scalars['String']>;
  equipmentEn?: Maybe<Scalars['String']>;
  id: Scalars['LotID'];
  inspection?: Maybe<Scalars['String']>;
  inspectionEn?: Maybe<Scalars['String']>;
  isSpecial: Scalars['Boolean'];
  mileage?: Maybe<Scalars['String']>;
  mileageEn?: Maybe<Scalars['String']>;
  mileageNum: Scalars['Int'];
  modelDetailsEn?: Maybe<Scalars['String']>;
  modelGrade?: Maybe<Scalars['String']>;
  modelGradeEn?: Maybe<Scalars['String']>;
  modelName: Scalars['String'];
  modelNameEn?: Maybe<Scalars['String']>;
  modelNameJp: Scalars['String'];
  modelNameRef: Scalars['Int'];
  modelType?: Maybe<Scalars['String']>;
  modelTypeEn?: Maybe<Scalars['String']>;
  modelYear: Scalars['String'];
  modelYearEn?: Maybe<Scalars['String']>;
  parsedData: Scalars['String'];
  parsedDataEn: Scalars['String'];
  parsedDataRu: Scalars['String'];
  picsDownloaded: Scalars['Int'];
  pictures: Scalars['String'];
  result: Scalars['String'];
  resultRef?: Maybe<Scalars['Int']>;
  scores?: Maybe<Scalars['String']>;
  scoresEn?: Maybe<Scalars['String']>;
  startPrice?: Maybe<Scalars['String']>;
  startPriceNum?: Maybe<Scalars['Int']>;
  swType?: Maybe<Scalars['String']>;
  transmission?: Maybe<Scalars['String']>;
  transmissionEn?: Maybe<Scalars['String']>;
  truckRef?: Maybe<Scalars['Int']>;
  vin?: Maybe<Scalars['String']>;
};

export type CarsDefaultOnepriceLotFilter = {
  aleadoAutoAuctionDayOfWeek?: Maybe<AleadoAutoAuctionDayOfWeekFilter>;
  bid?: Maybe<BidFilter>;
  companyRef?: Maybe<CompanyRefFilter>;
  date?: Maybe<DateFilter>;
  datetime?: Maybe<DatetimeFilter>;
  engineVolume?: Maybe<EngineVolumeFilter>;
  id?: Maybe<IdFilter>;
  inclusion?: Maybe<InclusionFilter>;
  mileage?: Maybe<MileageFilter>;
  modelGradeEn?: Maybe<ModelGradeEnFilter>;
  modelNameRef?: Maybe<ModelNameRefFilter>;
  productionDate?: Maybe<ProductionDateFilter>;
  truckRef?: Maybe<TruckRefFilter>;
  year?: Maybe<YearFilter>;
};

export type AleadoAutoAuctionDayOfWeekFilter = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
};

export type CarsDefaultOnepriceLotSort = {
  auctRef?: Maybe<AuctRefSort>;
  bid?: Maybe<BidSort>;
  colorEn?: Maybe<ColorEnSort>;
  date?: Maybe<DateSort>;
  displacementNum?: Maybe<DisplacementNumSort>;
  endPriceNum?: Maybe<EndPriceNumSort>;
  equipmentEn?: Maybe<EquipmentEnSort>;
  mileageNum?: Maybe<MileageNumSort>;
  modelTypeEn?: Maybe<ModelTypeEnSort>;
  modelYearEn?: Maybe<ModelYearEnSort>;
  scoresEn?: Maybe<ScoresEnSort>;
  startPriceNum?: Maybe<StartPriceNumSort>;
};

export type OnepriceLotPaginatedList = {
  __typename?: 'OnepriceLotPaginatedList';
  distinct?: Maybe<Array<OnepriceLot>>;
  distinctTotal?: Maybe<Array<ValueWithCount>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  nodes: Array<OnepriceLot>;
  total: Scalars['Int'];
};


export type OnepriceLotPaginatedListDistinctArgs = {
  fieldName: Scalars['String'];
};


export type OnepriceLotPaginatedListDistinctTotalArgs = {
  fieldName: Scalars['String'];
};


export type OnepriceLotPaginatedListMaxArgs = {
  fieldName: Scalars['String'];
};


export type OnepriceLotPaginatedListMinArgs = {
  fieldName: Scalars['String'];
};

export type AutoCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type AutoCompaniesQuery = { __typename?: 'Query', autoCompanies?: Maybe<{ __typename?: 'AutoCompanyPaginatedList', nodes: Array<{ __typename?: 'AutoCompany', id: string, companyId: string, name?: Maybe<string>, country?: Maybe<string> }> }> };

export type AutoLotQueryVariables = Exact<{
  id: Scalars['LotID'];
}>;


export type AutoLotQuery = { __typename?: 'Query', dumpVersion: string, autoLot?: Maybe<{ __typename?: 'AutoLot', id: any, bid: string, vin?: Maybe<string>, date: any, result?: Maybe<string>, companyRef?: Maybe<number>, modelNameRef: number, transmissionEn?: Maybe<string>, inspection?: Maybe<string>, inspectionEn?: Maybe<string>, parsedData?: Maybe<string>, parsedDataEn?: Maybe<string>, parsedDataRu?: Maybe<string>, source?: Maybe<LotSource>, time: any, company?: Maybe<string>, modelName?: Maybe<string>, modelGrade?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, color?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images: string, autoAuction?: Maybe<{ __typename?: 'AutoAuction', id: string, name: string }>, autoTruck?: Maybe<{ __typename?: 'AutoTruck', id: string, name: string }> }> };

export type LotsListFiltersQueryVariables = Exact<{
  withModel: Scalars['Boolean'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CarsDefaultAutoLotFilter>;
  sort?: Maybe<CarsDefaultAutoLotSort>;
}>;


export type LotsListFiltersQuery = { __typename?: 'Query', dumpVersion: string, autoAuctions?: Maybe<{ __typename?: 'AutoAuctionPaginatedList', nodes: Array<{ __typename?: 'AutoAuction', id: string, name: string, dayOfWeek?: Maybe<number>, numLots?: Maybe<number> }> }>, autoCompanies?: Maybe<{ __typename?: 'AutoCompanyPaginatedList', nodes: Array<{ __typename?: 'AutoCompany', id: string, companyId: string, name?: Maybe<string>, country?: Maybe<string> }> }>, autoModels?: Maybe<{ __typename?: 'AutoModelPaginatedList', nodes: Array<{ __typename?: 'AutoModel', id: string, modelId: string, companyRef: number, name: string, autoLotsTotal: number }> }>, autoTrucks?: Maybe<{ __typename?: 'AutoTruckPaginatedList', nodes: Array<{ __typename?: 'AutoTruck', id: string, name: string, truckId: string }> }>, lotsFiltersData?: Maybe<{ __typename?: 'AutoLotPaginatedList', modelTypeEnOptions?: Maybe<Array<{ __typename?: 'ValueWithCount', value?: Maybe<string>, count?: Maybe<number> }>>, colorEnOptions?: Maybe<Array<{ __typename?: 'AutoLot', colorEn?: Maybe<string> }>>, scoresEnOptions?: Maybe<Array<{ __typename?: 'AutoLot', scoresEn?: Maybe<string> }>>, auctRefOptions?: Maybe<Array<{ __typename?: 'AutoLot', auctRef?: Maybe<string>, autoAuction?: Maybe<{ __typename?: 'AutoAuction', id: string, name: string, dayOfWeek?: Maybe<number> }> }>>, transmissionEnOptions?: Maybe<Array<{ __typename?: 'AutoLot', transmissionEn?: Maybe<string> }>> }> };

export type LotsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CarsDefaultAutoLotFilter>;
  sort?: Maybe<CarsDefaultAutoLotSort>;
}>;


export type LotsListQuery = { __typename?: 'Query', dumpVersion: string, autoLots?: Maybe<{ __typename?: 'AutoLotPaginatedList', total: number }>, lots?: Maybe<{ __typename?: 'AutoLotPaginatedList', total: number, nodes: Array<{ __typename?: 'AutoLot', id: any, bid: string, date: any, result?: Maybe<string>, companyEn?: Maybe<string>, modelNameEn?: Maybe<string>, colorEn?: Maybe<string>, transmissionEn?: Maybe<string>, time: any, modelGrade?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images: string, autoAuction?: Maybe<{ __typename?: 'AutoAuction', id: string, name: string }>, autoTruck?: Maybe<{ __typename?: 'AutoTruck', id: string, name: string }> }> }> };

export type MotoLotQueryVariables = Exact<{
  id: Scalars['LotID'];
}>;


export type MotoLotQuery = { __typename?: 'Query', dumpVersion: string, motoLot?: Maybe<{ __typename?: 'MotoLot', id: any, bid: string, date: any, result?: Maybe<string>, companyRef?: Maybe<number>, modelNameRef?: Maybe<number>, vin?: Maybe<string>, time: any, company?: Maybe<string>, modelName?: Maybe<string>, modelGrade?: Maybe<string>, color?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images?: Maybe<string>, motoAuction: { __typename?: 'MotoAuction', name: string } }> };

export type MotoListLotsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CarsDefaultMotoLotFilter>;
  sort?: Maybe<CarsDefaultMotoLotSort>;
}>;


export type MotoListLotsQuery = { __typename?: 'Query', motoLots?: Maybe<{ __typename?: 'MotoLotPaginatedList', total: number }>, motoCompanies?: Maybe<{ __typename?: 'MotoCompanyPaginatedList', nodes: Array<{ __typename?: 'MotoCompany', companyId: string, name: string, motoModels: Array<{ __typename?: 'MotoModel', modelId: number, name: string, motoLotsTotal: number }> }> }>, lots?: Maybe<{ __typename?: 'MotoLotPaginatedList', total: number, minYear?: Maybe<number>, maxYear?: Maybe<number>, minEngineVolume?: Maybe<number>, maxEngineVolume?: Maybe<number>, minMileage?: Maybe<number>, maxMileage?: Maybe<number>, nodes: Array<{ __typename?: 'MotoLot', id: any, bid: string, result?: Maybe<string>, companyEn?: Maybe<string>, modelNameEn?: Maybe<string>, colorEn?: Maybe<string>, time: any, modelGrade?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images?: Maybe<string>, motoAuction: { __typename?: 'MotoAuction', name: string } }>, colorEnOptions?: Maybe<Array<{ __typename?: 'MotoLot', colorEn?: Maybe<string> }>>, motoCompanyOptions?: Maybe<Array<{ __typename?: 'MotoLot', companyRef?: Maybe<number>, motoCompany: { __typename?: 'MotoCompany', name: string } }>>, scoresEnOptions?: Maybe<Array<{ __typename?: 'MotoLot', scoresEn?: Maybe<string> }>>, auctRefOptions?: Maybe<Array<{ __typename?: 'MotoLot', auctRef?: Maybe<string>, motoAuction: { __typename?: 'MotoAuction', name: string } }>>, resultRefOptions?: Maybe<Array<{ __typename?: 'MotoLot', result?: Maybe<string> }>> }> };

export type OnepriceLotQueryVariables = Exact<{
  id: Scalars['LotID'];
}>;


export type OnepriceLotQuery = { __typename?: 'Query', dumpVersion: string, onepriceLot?: Maybe<{ __typename?: 'OnepriceLot', id: any, bid: string, vin?: Maybe<string>, date: any, result: string, companyRef?: Maybe<number>, modelNameRef: number, transmissionEn?: Maybe<string>, inspection?: Maybe<string>, inspectionEn?: Maybe<string>, parsedData: string, parsedDataEn: string, parsedDataRu: string, time: any, company?: Maybe<string>, modelName?: Maybe<string>, modelGrade?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, color?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images: string, autoAuction: { __typename?: 'AleadoAutoAuction', id: string, name: string }, autoTruck?: Maybe<{ __typename?: 'AutoTruck', id: string, name: string }> }> };

export type OnepriceLotsListFiltersQueryVariables = Exact<{
  companyId: Scalars['ID'];
  modelId: Scalars['ID'];
  withCompany: Scalars['Boolean'];
  withModel: Scalars['Boolean'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CarsDefaultOnepriceLotFilter>;
  sort?: Maybe<CarsDefaultOnepriceLotSort>;
}>;


export type OnepriceLotsListFiltersQuery = { __typename?: 'Query', dumpVersion: string, autoCompany?: Maybe<{ __typename?: 'AleadoAutoCompany', id: string, name?: Maybe<string>, companyId: string, autoModel?: Maybe<{ __typename?: 'AleadoAutoModel', id: string, name: string, modelId: string }> }>, autoCompanies?: Maybe<{ __typename?: 'AleadoAutoCompanyPaginatedList', nodes: Array<{ __typename?: 'AleadoAutoCompany', id: string, companyId: string, name?: Maybe<string>, country?: Maybe<string>, autoModels: Array<{ __typename?: 'AleadoAutoModel', id: string, modelId: string, name: string, autoLotsTotal: number }> }> }>, autoTrucks?: Maybe<{ __typename?: 'AutoTruckPaginatedList', nodes: Array<{ __typename?: 'AutoTruck', id: string, name: string, truckId: string }> }>, lotsFiltersData?: Maybe<{ __typename?: 'OnepriceLotPaginatedList', minYear?: Maybe<number>, maxYear?: Maybe<number>, minMileage?: Maybe<number>, maxMileage?: Maybe<number>, minEngineVolume?: Maybe<number>, maxEngineVolume?: Maybe<number>, modelTypeEnOptions?: Maybe<Array<{ __typename?: 'ValueWithCount', value?: Maybe<string>, count?: Maybe<number> }>>, colorEnOptions?: Maybe<Array<{ __typename?: 'OnepriceLot', colorEn?: Maybe<string> }>>, scoresEnOptions?: Maybe<Array<{ __typename?: 'OnepriceLot', scoresEn?: Maybe<string> }>>, auctRefOptions?: Maybe<Array<{ __typename?: 'OnepriceLot', auctRef?: Maybe<string>, autoAuction: { __typename?: 'AleadoAutoAuction', id: string, name: string } }>>, transmissionEnOptions?: Maybe<Array<{ __typename?: 'OnepriceLot', transmissionEn?: Maybe<string> }>>, resultRefOptions?: Maybe<Array<{ __typename?: 'OnepriceLot', result: string }>> }> };

export type OnepriceLotsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CarsDefaultOnepriceLotFilter>;
  sort?: Maybe<CarsDefaultOnepriceLotSort>;
}>;


export type OnepriceLotsListQuery = { __typename?: 'Query', dumpVersion: string, onepriceLots?: Maybe<{ __typename?: 'OnepriceLotPaginatedList', total: number }>, lots?: Maybe<{ __typename?: 'OnepriceLotPaginatedList', total: number, nodes: Array<{ __typename?: 'OnepriceLot', id: any, bid: string, result: string, companyEn?: Maybe<string>, modelNameEn?: Maybe<string>, colorEn?: Maybe<string>, transmissionEn?: Maybe<string>, time: any, modelGrade?: Maybe<string>, year?: Maybe<string>, frame?: Maybe<string>, engineVolume?: Maybe<number>, equip?: Maybe<string>, mileage?: Maybe<string>, rate?: Maybe<string>, startPrice?: Maybe<number>, finishPrice?: Maybe<number>, images: string, autoAuction: { __typename?: 'AleadoAutoAuction', id: string, name: string }, autoTruck?: Maybe<{ __typename?: 'AutoTruck', id: string, name: string }> }> }> };

export type StatsFiltersQueryVariables = Exact<{
  filter?: Maybe<CarsDefaultAutoLotFilter>;
}>;


export type StatsFiltersQuery = { __typename?: 'Query', dumpVersion: string, filtersData?: Maybe<{ __typename?: 'AutoLotPaginatedList', yearOptions?: Maybe<Array<{ __typename?: 'AutoLot', modelYearEn?: Maybe<string> }>>, frameOptions?: Maybe<Array<{ __typename?: 'AutoLot', modelTypeEn?: Maybe<string> }>>, scoresOptions?: Maybe<Array<{ __typename?: 'AutoLot', scoresEn?: Maybe<string> }>> }> };

export const AutoCompaniesDocument = gql`
    query autoCompanies {
  autoCompanies {
    nodes {
      id
      companyId
      name
      country
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoCompaniesGQL extends Apollo.Query<AutoCompaniesQuery, AutoCompaniesQueryVariables> {
    document = AutoCompaniesDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AutoLotDocument = gql`
    query autoLot($id: LotID!) {
  dumpVersion
  autoLot(id: $id) {
    id
    bid
    vin
    date
    time: datetime
    autoAuction {
      id
      name
    }
    autoTruck {
      id
      name
    }
    result
    company: companyEn
    companyRef
    modelName: modelNameEn
    modelNameRef
    modelGrade: modelGradeEn
    year: modelYearEn
    frame: modelTypeEn
    engineVolume: displacementNum
    equip: equipmentEn
    transmissionEn
    color: colorEn
    mileage: mileageEn
    rate: scoresEn
    startPrice: startPriceNum
    finishPrice: endPriceNum
    images: pictures
    inspection
    inspectionEn
    parsedData
    parsedDataEn
    parsedDataRu
    source
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AutoLotGQL extends Apollo.Query<AutoLotQuery, AutoLotQueryVariables> {
    document = AutoLotDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotsListFiltersDocument = gql`
    query lotsListFilters($withModel: Boolean!, $first: Int, $offset: Int, $filter: CarsDefaultAutoLotFilter, $sort: CarsDefaultAutoLotSort) {
  dumpVersion
  autoAuctions {
    nodes {
      id
      name
      dayOfWeek
      numLots
    }
  }
  autoCompanies {
    nodes {
      id
      companyId
      name
      country
    }
  }
  autoModels {
    nodes {
      id
      modelId
      companyRef
      name
      autoLotsTotal
    }
  }
  autoTrucks {
    nodes {
      id
      name
      truckId
    }
  }
  lotsFiltersData: autoLotsAggregates(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    modelTypeEnOptions: distinctTotal(fieldName: "modelTypeEn") @include(if: $withModel) {
      value
      count
    }
    colorEnOptions: distinct(fieldName: "colorEn") @include(if: $withModel) {
      colorEn
    }
    scoresEnOptions: distinct(fieldName: "scoresEn") {
      scoresEn
    }
    auctRefOptions: distinct(fieldName: "auctRef") {
      auctRef
      autoAuction {
        id
        name
        dayOfWeek
      }
    }
    transmissionEnOptions: distinct(fieldName: "transmissionEn") {
      transmissionEn
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotsListFiltersGQL extends Apollo.Query<LotsListFiltersQuery, LotsListFiltersQueryVariables> {
    document = LotsListFiltersDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LotsListDocument = gql`
    query lotsList($first: Int, $offset: Int, $filter: CarsDefaultAutoLotFilter, $sort: CarsDefaultAutoLotSort) {
  dumpVersion
  autoLots {
    total
  }
  lots: autoLots(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    nodes {
      id
      bid
      date
      time: datetime
      autoAuction {
        id
        name
      }
      autoTruck {
        id
        name
      }
      result
      companyEn
      modelNameEn
      modelGrade: modelGradeEn
      colorEn
      year: modelYearEn
      frame: modelTypeEn
      engineVolume: displacementNum
      equip: equipmentEn
      transmissionEn
      colorEn
      mileage: mileageEn
      rate: scoresEn
      startPrice: startPriceNum
      finishPrice: endPriceNum
      images: pictures
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LotsListGQL extends Apollo.Query<LotsListQuery, LotsListQueryVariables> {
    document = LotsListDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MotoLotDocument = gql`
    query motoLot($id: LotID!) {
  dumpVersion
  motoLot(id: $id) {
    id
    bid
    date
    time: datetime
    result
    company: companyEn
    modelName: modelNameEn
    modelGrade: modelGradeEn
    color: colorEn
    year: modelYearEn
    frame: modelTypeEn
    engineVolume: displacementNum
    equip: equipmentEn
    mileage: mileageEn
    rate: scoresEn
    startPrice: startPriceNum
    finishPrice: endPriceNum
    images: pictures
    companyRef
    modelNameRef
    vin
    motoAuction {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MotoLotGQL extends Apollo.Query<MotoLotQuery, MotoLotQueryVariables> {
    document = MotoLotDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MotoListLotsDocument = gql`
    query motoListLots($first: Int, $offset: Int, $filter: CarsDefaultMotoLotFilter, $sort: CarsDefaultMotoLotSort) {
  motoLots {
    total
  }
  motoCompanies {
    nodes {
      companyId
      motoModels {
        modelId
        name
        motoLotsTotal
      }
      name
    }
  }
  lots: motoLots(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    nodes {
      id
      bid
      time: datetime
      result
      companyEn
      modelNameEn
      modelGrade: modelGradeEn
      colorEn
      year: modelYearEn
      frame: modelTypeEn
      engineVolume: displacementNum
      equip: equipmentEn
      mileage: mileageEn
      rate: scoresEn
      startPrice: startPriceNum
      finishPrice: endPriceNum
      images: pictures
      motoAuction {
        name
      }
    }
    minYear: min(fieldName: "modelYearEn")
    maxYear: max(fieldName: "modelYearEn")
    minEngineVolume: min(fieldName: "displacementNum")
    maxEngineVolume: max(fieldName: "displacementNum")
    minMileage: min(fieldName: "mileageNum")
    maxMileage: max(fieldName: "mileageNum")
    colorEnOptions: distinct(fieldName: "colorEn") {
      colorEn
    }
    motoCompanyOptions: distinct(fieldName: "companyEn") {
      companyRef
      motoCompany {
        name
      }
    }
    scoresEnOptions: distinct(fieldName: "scoresEn") {
      scoresEn
    }
    auctRefOptions: distinct(fieldName: "auctRef") {
      auctRef
      motoAuction {
        name
      }
    }
    resultRefOptions: distinct(fieldName: "resultRef") {
      result
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MotoListLotsGQL extends Apollo.Query<MotoListLotsQuery, MotoListLotsQueryVariables> {
    document = MotoListLotsDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnepriceLotDocument = gql`
    query onepriceLot($id: LotID!) {
  dumpVersion
  onepriceLot(id: $id) {
    id
    bid
    vin
    date
    time: datetime
    autoAuction {
      id
      name
    }
    autoTruck {
      id
      name
    }
    result
    company: companyEn
    companyRef
    modelName: modelNameEn
    modelNameRef
    modelGrade: modelGradeEn
    year: modelYearEn
    frame: modelTypeEn
    engineVolume: displacementNum
    equip: equipmentEn
    transmissionEn
    color: colorEn
    mileage: mileageEn
    rate: scoresEn
    startPrice: startPriceNum
    finishPrice: endPriceNum
    images: pictures
    inspection
    inspectionEn
    parsedData
    parsedDataEn
    parsedDataRu
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OnepriceLotGQL extends Apollo.Query<OnepriceLotQuery, OnepriceLotQueryVariables> {
    document = OnepriceLotDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnepriceLotsListFiltersDocument = gql`
    query onepriceLotsListFilters($companyId: ID!, $modelId: ID!, $withCompany: Boolean!, $withModel: Boolean!, $first: Int, $offset: Int, $filter: CarsDefaultOnepriceLotFilter, $sort: CarsDefaultOnepriceLotSort) {
  dumpVersion
  autoCompany: aleadoAutoCompany(id: $companyId) @include(if: $withCompany) {
    id
    name
    companyId
    autoModel(id: $modelId) @include(if: $withModel) {
      id
      name
      modelId
    }
  }
  autoCompanies: aleadoAutoCompanies {
    nodes {
      id
      companyId
      name
      country
      autoModels {
        id
        modelId
        name
        autoLotsTotal
      }
    }
  }
  autoTrucks {
    nodes {
      id
      name
      truckId
    }
  }
  lotsFiltersData: onepriceLotsAggregates(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    minYear: min(fieldName: "modelYearEn")
    maxYear: max(fieldName: "modelYearEn")
    minMileage: min(fieldName: "mileageNum")
    maxMileage: max(fieldName: "mileageNum")
    minEngineVolume: min(fieldName: "displacementNum")
    maxEngineVolume: max(fieldName: "displacementNum")
    modelTypeEnOptions: distinctTotal(fieldName: "modelTypeEn") @include(if: $withCompany) {
      value
      count
    }
    colorEnOptions: distinct(fieldName: "colorEn") @include(if: $withCompany) {
      colorEn
    }
    scoresEnOptions: distinct(fieldName: "scoresEn") {
      scoresEn
    }
    auctRefOptions: distinct(fieldName: "auctRef") {
      auctRef
      autoAuction {
        id
        name
      }
    }
    transmissionEnOptions: distinct(fieldName: "transmissionEn") {
      transmissionEn
    }
    resultRefOptions: distinct(fieldName: "resultRef") {
      result
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OnepriceLotsListFiltersGQL extends Apollo.Query<OnepriceLotsListFiltersQuery, OnepriceLotsListFiltersQueryVariables> {
    document = OnepriceLotsListFiltersDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OnepriceLotsListDocument = gql`
    query onepriceLotsList($first: Int, $offset: Int, $filter: CarsDefaultOnepriceLotFilter, $sort: CarsDefaultOnepriceLotSort) {
  dumpVersion
  onepriceLots {
    total
  }
  lots: onepriceLots(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    nodes {
      id
      bid
      time: datetime
      autoAuction {
        id
        name
      }
      autoTruck {
        id
        name
      }
      result
      companyEn
      modelNameEn
      modelGrade: modelGradeEn
      colorEn
      year: modelYearEn
      frame: modelTypeEn
      engineVolume: displacementNum
      equip: equipmentEn
      transmissionEn
      colorEn
      mileage: mileageEn
      rate: scoresEn
      startPrice: startPriceNum
      finishPrice: endPriceNum
      images: pictures
    }
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OnepriceLotsListGQL extends Apollo.Query<OnepriceLotsListQuery, OnepriceLotsListQueryVariables> {
    document = OnepriceLotsListDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatsFiltersDocument = gql`
    query statsFilters($filter: CarsDefaultAutoLotFilter) {
  dumpVersion
  filtersData: autoLotsAggregates(filter: $filter) {
    yearOptions: distinct(fieldName: "modelYearEn") {
      modelYearEn
    }
    frameOptions: distinct(fieldName: "modelTypeEn") {
      modelTypeEn
    }
    scoresOptions: distinct(fieldName: "scoresEn") {
      scoresEn
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StatsFiltersGQL extends Apollo.Query<StatsFiltersQuery, StatsFiltersQueryVariables> {
    document = StatsFiltersDocument;
    client = 'cars-default';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { Bid, UserInfo } from '../../graphql/service/graphql-auto-main-service';
import { ColorPoolFactoryService } from './color-pool/color-pool-factory.service';

type ColorById = {
  [id: string]: string;
};

@Component({
  selector: 'app-lot-bids',
  templateUrl: './lot-bids.component.html',
  styleUrls: ['./lot-bids.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotBidsComponent implements OnInit {
  @Input() bids: Bid[];
  @Input() fullSize = false;

  usersTotal = 0;
  modalVisible = false;

  userColorsMap: ColorById = {};

  tagColorMap = {
    initial: 'default',
    confirmed: 'processing',
    won: 'success',
    canceled: 'default',
  };

  constructor(private colorPoolFactory: ColorPoolFactoryService) {}

  ngOnInit(): void {
    this.bids = this.bids ?? [];
    this.usersTotal = new Set(this.bids.map((b) => b.user.id)).size;

    const colorPool = this.colorPoolFactory.create({ ifEmptyReturn: 'random' });
    this.userColorsMap = this.bids.reduce((map: ColorById, b) => {
      if (!Boolean(map[b.user.id])) {
        map[b.user.id] = colorPool.get();
      }
      return map;
    }, {});
  }

  getUserUrl(user: UserInfo): string {
    return `${environment.managerDomain}/akebono-auctioneer/users/edit/${user.id}`;
  }

  bidFullAmountSort(a: Bid, b: Bid): number {
    return a.fullAmount - b.fullAmount;
  }

  bidCreatedAtSort(a: Bid, b: Bid): number {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }

  bidUserFullNameSort(a: Bid, b: Bid): number {
    return a.user.fullName.toLowerCase().localeCompare(b.user.fullName.toLowerCase());
  }
}

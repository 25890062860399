import { TypeEnum } from '@akebono/calculator';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { AutoLotView } from '../../../const';
import { Limits } from '../../../services/limits.service';
import {
  LotSource,
  LotTypeEnum,
  UserLotQuery,
} from '../../graphql/service/graphql-auto-main-service';
import { LotBiddingModalComponent } from '../lot-bidding-modal/lot-bidding-modal.component';

type ButtonDisabledReasons =
  | 'data-changes'
  | 'moto-overprice'
  | 'time-over'
  | 'bids-limit'
  | 'lots-limit'
  | 'chance-used';

@Component({
  selector: 'app-lot-place-bid-button',
  templateUrl: './lot-place-bid-button.component.html',
  styleUrls: ['./lot-place-bid-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotPlaceBidButtonComponent implements OnChanges {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() hasTranslations = false;
  @Input() limits: Limits;
  @Input() calculatorTypes: TypeEnum[] = [];
  @Input() currentUserLimitsOverrideAvailable = false;
  @Input() currentUserLastBid?: UserLotQuery['lot']['currentUserLastBid'];
  @Input()
  @HostBinding('class.full-size')
  fullSize = false;
  @Output() afterSubmit = new EventEmitter<boolean>();

  showChanceConfirm = false;
  withLimitsOverride = false;

  canRenderPlaceBidButton$ = of(false);
  canRenderLastChanceButton$ = of(false);

  isPlaceBidDisabled$ = of(false);
  isLastChanceDisabled$ = of(false);

  placeBidButtonDisabledReasons$: Observable<ButtonDisabledReasons[]> = of([]);
  lastChangeButtonDisabledReasons$: Observable<ButtonDisabledReasons[]> = of([]);

  constructor(private translate: TranslateService, private modalService: NzModalService) {}

  placeBid(): void {
    this.modalService.create({
      nzContent: LotBiddingModalComponent,
      nzFooter: null,
      nzWidth: '540px',
      nzComponentParams: {
        lot: this.lot,
        lotType: this.lotType,
        lotSource: this.lotSource,
        limits: this.limits,
        hasTranslations: this.hasTranslations,
        currentUserLastBid: this.currentUserLastBid,
        withLimitsOverride: this.withLimitsOverride,
        calculatorTypes: this.calculatorTypes,
        renderCalculator: true,
        afterSubmit: this.afterSubmit,
      },
    });
  }

  get isMotoOverprice(): boolean {
    return this.lotType === LotTypeEnum.Moto && this.lot?.startPrice >= 9999000;
  }

  ngOnChanges(): void {
    this.canRenderLastChanceButton$ = this.limits.lotBidsLimitReached$.pipe(map((limit) => limit));
    this.canRenderPlaceBidButton$ = this.limits.lotBidsLimitReached$.pipe(
      map((limit) => !limit),
      startWith(true),
    );

    this.placeBidButtonDisabledReasons$ = combineLatest([
      of(this.lot.isLotDataChanged).pipe(map((result) => (result ? 'data-changes' : null))),
      of(this.isMotoOverprice).pipe(map((result) => (result ? 'moto-overprice' : null))),
      this.limits.timeLimitReached$.pipe(map((result) => (result ? 'time-over' : null))),
      this.limits.lotBidsLimitReached$.pipe(map((result) => (result ? 'bids-limit' : null))),
      this.limits.lotsLimitReached$.pipe(map((result) => (result ? 'lots-limit' : null))),
    ]).pipe(
      map((reasons) => reasons.filter((r) => !!r)),
      shareReplay(1),
    ) as Observable<ButtonDisabledReasons[]>;

    this.lastChangeButtonDisabledReasons$ = combineLatest([
      of(this.lot.isLotDataChanged).pipe(map((result) => (result ? 'data-changes' : null))),
      of(this.isMotoOverprice).pipe(map((result) => (result ? 'moto-overprice' : null))),
      this.limits.timeLimitReached$.pipe(map((result) => (result ? 'time-over' : null))),
      of(this.currentUserLimitsOverrideAvailable).pipe(
        map((result) => (result ? null : 'chance-used')),
      ),
    ]).pipe(
      map((reasons) => reasons.filter((r) => !!r)),
      shareReplay(1),
    ) as Observable<ButtonDisabledReasons[]>;

    this.isPlaceBidDisabled$ = this.placeBidButtonDisabledReasons$.pipe(
      map((reasons) => !!reasons.length),
      startWith(true),
    );
    this.isLastChanceDisabled$ = this.lastChangeButtonDisabledReasons$.pipe(
      map((reasons) => !!reasons.length),
      startWith(true),
    );
  }
}

export enum LotActionEnum {
  PlaceBid = 'place-bid',
  Share = 'share',
  Translate = 'translate',
  AddPhoto = 'add-photo',
  Bids = 'bids',
  Events = 'events',
  Reset = 'reset',
  PurchaseReport = 'purchase-report',
  CreateTranslationRequest = 'create-translation-request',
  GetPhotoRequest = 'get-photo-request',
  PreviousSales = 'previous-sales',
  Notations = 'notations',
}

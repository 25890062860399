const domain = 'lots-staging.link';

export const environment = {
  production: true,
  fullDomain: `lots.${domain}`,
  newsDomain: domain,
  staging: true,
  domain,
  imageProxy: `https://prx.${domain}/`,
  managerDomain: 'https://manager.twilight.link',
  graphql: [
    {
      clientName: 'authentication',
      endpoint: `https://${domain}/auto/main`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'agreements',
      endpoint: `https://${domain}/auto/main`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'user-info',
      endpoint: `https://${domain}/auto/main`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'cars-default',
      endpoint: `https://${domain}/cars/default`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'auto-main',
      endpoint: `https://${domain}/auto/main`,
    },
    {
      clientName: 'auto-shared',
      endpoint: 'https://api.twilight.link/auto/shared',
    },
    {
      clientName: 'calculator',
      endpoint: `https://${domain}/auto/calculator`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'currency-exchange',
      endpoint: `https://${domain}/auto/main`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'content',
      endpoint: `https://${domain}/content/auto-minimal`,
      fetchPolicy: 'cache-first',
    },
    {
      clientName: 'directory',
      endpoint: `https://${domain}/graphql/directory/common/open`,
    },
    {
      clientName: 'shared-familiar',
      endpoint: `https://${domain}/graphql/ecarjp/shared/familiar`,
    },
    {
      clientName: 'stats-auto',
      endpoint: `https://${domain}/stats/auto`,
      fetchPolicy: 'cache-first',
      method: 'GET',
    },
  ],
  cognitoPool: {
    ClientId: '12nnuhp1edi7q97u5nqgdujdm2',
    UserPoolId: 'us-east-1_XGTHN4PBv',
  },
};

import { MutationHandlingServiceV2, SingleMutationRef } from '@akebono/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AutoLotView } from '../../../../../const';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
  UserLotMarkCreateGQL,
  UserLotMarkCreateMutation,
  UserLotMarkEnum,
} from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-share-button',
  templateUrl: './lot-share-button.component.html',
  styleUrls: ['./lot-share-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotShareButtonComponent implements OnDestroy {
  @Input() lot: AutoLotView;
  @Input() shareLotLink = '';
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() userLotMarks: UserLotMark[];
  @Input() nzSize: NzButtonSize = 'large';
  @Input() fullSize = false;
  @Output() changeMark = new EventEmitter<void>();

  visible = false;
  private destroy$ = new Subject();
  refShared$: SingleMutationRef<UserLotMarkCreateMutation>;

  constructor(
    private clipboard: Clipboard,
    private message: NzMessageService,
    private translate: TranslateService,
    private mhs: MutationHandlingServiceV2,
    private userLotMarkCreateGQL: UserLotMarkCreateGQL,
  ) {}

  userLotMarkCreate(): void {
    if (!this.shareLotLink) {
      this.refShared$ = this.mhs.mutate(
        this.userLotMarkCreateGQL,
        {
          input: {
            lotId: this.lot.id,
            lotType: this.lotType,
            lotSource: this.lotSource,
            mark: UserLotMarkEnum.Shared,
          },
        },
        {
          refetch: true,
          failureTranslationKey: 'MUTATION_FAILED',
        },
      );

      this.refShared$.loading.pipe(
        finalize(() => {
          this.changeMark.emit();
        }),
      );

      this.refShared$.data.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        this.shareLotLink = `https://${environment.domain}/shared/${res.userLotMarkCreate.userLotMark.id} ${this.lot.auctionName} ${this.lot.bid}`;
        this.copyLinkClipboard();
      });
    } else {
      this.copyLinkClipboard();
    }
  }

  copyLinkClipboard(): void {
    this.clipboard.copy(this.shareLotLink);
    this.message.create('success', this.translate.instant('SHARED.COPY_SHARED_SUCCESS'));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
